import { AUTH_STORE }                                           from "../../Api/Conexao";
import { _lang_base }                                           from "../../Languages/languages";
import { verify_token_expired }                                 from "../UserFunction";
import { validate_payment_data }                                from "../StoreFunction";

const CHECKOUT_AUTH = async (current_user) => {
  const products     = current_user.products_cart.map((item) => ({quantity: item.product_amount, product_id: item.product_id}));
  let payment_credit = {};

   const validade_info_payment = validate_payment_data(current_user);
   if (!validade_info_payment.success) return validade_info_payment;  
 
  if(current_user.payment_method === "5") {
    const [asaas_cc_expiration_month, asaas_cc_expiration_year] = current_user.payment_credit.payment_credit_valid.split('/');
    payment_credit                                              = {
      asaas_cc_number: current_user.payment_credit.payment_credit_number.replace(/\s+/g, ''),
      asaas_cc_expiration_month,
      asaas_cc_expiration_year,
      asaas_cc_name: current_user.payment_credit.payment_credit_name,
      asaas_cc_security_code: current_user.payment_credit.payment_credit_code,
    }
  }

  try {
    const data = new URLSearchParams();
    data.append('token',               current_user.user.user_token_product);
    data.append('products',            JSON.stringify(products));
    data.append('payment_currency',    current_user.payment_currency);
    data.append('payment_method',      current_user.payment_method);
    data.append('payment_email',       current_user.payment_data.payment_email);
    data.append('payment_first_name',  current_user.payment_data.payment_first_name);
    data.append('payment_last_name',   current_user.payment_data.payment_last_name);
    data.append('payment_cpf',         current_user.payment_data.payment_cpf.replace(/[.\\-]/g, ''));
    data.append('payment_credit',      JSON.stringify(payment_credit));
    data.append('coupon_code',         (current_user.coupon_code !== undefined) ? current_user.coupon_code : "");
    data.append('referral_code',       "");
    
    data.append('g-recaptcha-response', current_user.rpf);
    const _checkout_auth = await AUTH_STORE.post('/routes/checkout.php', data).then((response) => response.data);
    _checkout_auth.status_code_message = _lang_base.requests[_checkout_auth.status_code];
    return _checkout_auth;
  } catch (error) {
    verify_token_expired(error.response.data.status_code);
    const status_code = _lang_base.requests[error.response.data.status_code];
    return { status_code_message: status_code, success: error.response.data.success };
  }
}

export default CHECKOUT_AUTH;
