import { _remove_storage, storage_vars } from "../Storage/Storage";
import { _lang_base }                    from "../Languages/languages";

function validate_email(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

function validate_name(name) {
  const has_valid_length            = name.length > 1;
  const has_only_allowed_characters = /^[\p{L}\p{N}\s]*$/u.test(name); // permite letras, números e espaços
  return has_valid_length && has_only_allowed_characters;
};

function validate_cpf(cpf) {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;
  const calcCheckDigit = (cpf, factor) => {
    let total = 0;
    for (let i = 0; i < factor - 1; i++) {
      total += cpf[i] * (factor - i);
    }
    const remainder = (total * 10) % 11;
    return remainder === 10 ? 0 : remainder;
  };
  const firstCheckDigit = calcCheckDigit(cpf, 10);
  const secondCheckDigit = calcCheckDigit(cpf, 11);
  return firstCheckDigit === parseInt(cpf[9]) && secondCheckDigit === parseInt(cpf[10]);
};

function validate_card_number(number) {
  const regex = /^[0-9]{16}$/;
  return regex.test(number.replace(/\s+/g, ''));
};

function validate_expiry_date(date) {
  const regex = /^(0[1-9]|1[0-2])\/([0-9]{2})$/;
  if (!regex.test(date)) return false;
  
  const [month, year] = date.split('/');
  const expiryDate = new Date(`20${year}`, month);
  return expiryDate > new Date();
};

function validate_cvv(cvv) {
  const regex = /^[0-9]{3,4}$/;
  return regex.test(cvv);
};

function apply_coupon_discont(coupon, cart_total) {
  let discount = 0;
  switch(coupon.discount_type) {
    case "percent":
      discount = (cart_total * coupon.amount) / 100;
      break;
    case "fixed_cart":
      discount = parseFloat(coupon.amount);
      break;
    default:
      break;
  }
  return cart_total - discount;
}

export function validate_payment_amount(current_user) {
  if (isNaN(current_user.product_value) || !/^\d+(\.\d{1,2})?$/.test(current_user.product_value)) return { status_code_message: _lang_base.requests["927"], success: false };
  if (parseFloat(current_user.product_value) < 6.00) return { status_code_message: _lang_base.requests["928"], success: false };
  return { success: true };
}

export function validate_payment_data(current_user) {
  if (!validate_email(current_user.payment_data.payment_email)) {
    return { status_code_message: _lang_base.requests["603"], success: false };
  }
  if (!validate_name(current_user.payment_data.payment_first_name)) {
    return { status_code_message: _lang_base.requests["613"], success: false };
  }
  if (!validate_name(current_user.payment_data.payment_last_name)) {
    return { status_code_message: _lang_base.requests["614"], success: false };
  }
  if (!validate_cpf(current_user.payment_data.payment_cpf) && current_user.payment_currency === "BRL") {
    return { status_code_message: _lang_base.requests["615"], success: false };
  }

  if (current_user.payment_method === "5") {
    if (!validate_card_number(current_user.payment_credit.payment_credit_number)) {
      return { status_code_message: _lang_base.requests["915"], success: false };
    }
    if (!validate_expiry_date(current_user.payment_credit.payment_credit_valid)) {
      return { status_code_message: _lang_base.requests["916"], success: false };
    }
    if (!validate_name(current_user.payment_credit.payment_credit_name)) {
      return { status_code_message: _lang_base.requests["917"], success: false };
    }
    if (!validate_cvv(current_user.payment_credit.payment_credit_code)) {
      return { status_code_message: _lang_base.requests["918"], success: false };
    }
  }

  return { success: true };
};

export function gen_coin_type(value, code_coin) {
  let coin_value;
  value                 = String(value).replace(",", ".");
  const numeric_value   = parseFloat(value);
  
  if (isNaN(numeric_value)) return "00.00";
  switch (code_coin) {
    case 'BRL':
      coin_value = 'R$' + numeric_value.toFixed(2).replace('.', ',');
      break;
    case 'USD':
      coin_value = '$' + numeric_value.toFixed(2);
      break;
    default:
      coin_value = '$' + numeric_value.toFixed(2);
  }
  return  coin_value;
}

export function gen_products(Products, _lang_base) {
  Products.vip          = Products.vip.map((item) =>   ({
    ...item,
    product_information: (_lang_base.lang_base === "pt_BR") ? item.product_information_brl : item.product_information_outside
  }));

  Products.free          = Products.free.map((item) =>   ({
    ...item,
    product_information: (_lang_base.lang_base === "pt_BR") ? item.product_information_brl : item.product_information_outside
  }));

  for (let objeto of Products.vip) {
    delete objeto.product_information_brl;
    delete objeto.product_information_outside;
  }

  for (let objeto of Products.free) {
    delete objeto.product_information_brl;
    delete objeto.product_information_outside;
  }

  return Products;
}

export function total_values(products_cart, CouponsInfo) {
  const value_total = products_cart.reduce((acc, ctv) => acc + (ctv.product_amount * ctv.product_information.product_discont), 0.00);
  return (CouponsInfo !== undefined) ? apply_coupon_discont(CouponsInfo, value_total) : value_total;
}

export function type_discont(CouponsInfo) {
  let discount = `${CouponsInfo.amount}%`;
  switch(CouponsInfo.discount_type) {
    case "percent":
      discount = `${CouponsInfo.amount}%`;
      break;
    case "fixed_cart":
      discount = `${CouponsInfo.amount}`;
      break;
    default:
      break;
  }
  return discount;
}

export function fomate_date(date_string) {
  const date    = new Date(date_string);
  const day     = String(date.getDate()).padStart(2, '0');
  const month   = String(date.getMonth() + 1).padStart(2, '0');
  const year    = date.getFullYear();
  const hours   = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export function remove_coupon_discont() {
  _remove_storage(storage_vars.coupons);
  window.location.reload();
}
