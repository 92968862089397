const _lang_checkout = {
  payment_methods: {
    pix_mercado_pago: {
      payment_title: "PIX",
      payment_id: "1",
      payment_description: "Pay securely and instantly",
    },
    pix_asaas: {
      payment_title: "PIX",
      payment_id: "2",
      payment_description: "Pay securely and instantly",
    },
    stripe: {
      payment_title: "CREDIT CARD",
      payment_id: "3",
      payment_description: "Secure payment via Stripe Checkout",
    },
    mercado_pago: {
      payment_title: "MERCADO PAGO",
      payment_id: "4",
      payment_description: "Log in to Mercado Pago and get advantages",
    },
    asaas_credit: {
      payment_title: "CREDIT CARD",
      payment_id: "5",
      payment_description: "Make your payment by credit card directly on the website",
    },
  },
  section_title: "CHECKOUT",
  section_pretitle: "Your Order",
  section_info_title: "Complete Purchase",
  widget_title: "Billing Details",
  inputs_users: {
    input_first_name: "First Name",
    input_last_name: "Last Name",
    input_email: "Email",
    input_cpf: "Cpf",
  },
  sidebar_title: "Order Summary",
  sidebar_payment_type: "Payment Method",
  btn_payment: "Complete Order",
  generating_purchase: "Please wait, we are generating your order",
  payment_released: "Payment enabled only for Brazil",
  accept_terms_error: "You need to accept the terms",
  check_accept_terms: "Accept terms",
  widget_title_credit: "CARD INFORMATION",
  inputs_users_credit: {
    input_credit_number: "Card Number",
    input_credit_valid: "Validity (MM/YY)",
    input_credit_name: "Cardholder",
    input_credit_code: "Code (CVV)"
  },
  title_information: "Please be aware that none of your information is saved, your data is passed on to the payment company and once validated it is removed",
  text_discont_message: "If you have a promotional or a discount code, please enter it right here to redeem it!",
  text_select_value_order: "Enter the mod amount to make the payment, remember to send the receipt to the administrator",
  btn_discont: "Redeem Code",
};

module.exports = { _lang_checkout };
