const _lang_checkout = {
  payment_methods: {
    pix_mercado_pago: {
      payment_title: "PIX",
      payment_id: "1",
      payment_description: "Pague de forma segura e instantânea",
    },
    pix_asaas: {
      payment_title: "PIX",
      payment_id: "2",
      payment_description: "Pague de forma segura e instantânea",
    },
    stripe: {
      payment_title: "CARTÃO DE CRÉDITO",
      payment_id: "3",
      payment_description: "Pagamento seguro via Stripe Checkout",
    },
    mercado_pago: {
      payment_title: "MERCADO PAGO",
      payment_id: "4",
      payment_description: "Inicie sessão no Mercado Pago e garanta vantagens",
    },
    asaas_credit: {
      payment_title: "CARTÃO DE CRÉDITO",
      payment_id: "5",
      payment_description: "Faça seu pagamento com cartão de crédito direto no site",
    },
  },
  section_title: "FINALIZAR COMPRA",
  section_pretitle: "Seu pedido",
  section_info_title: "Finalizar Compra",
  widget_title: "Detalhes de cobrança",
  inputs_users: {
    input_first_name: "Primeiro nome",
    input_last_name: "Sobrenome",
    input_email: "Email",
    input_cpf: "Cpf",
  },
  sidebar_title: "Resumo do Pedido",
  sidebar_payment_type: "Forma de pagamento",
  btn_payment: "Finalizar Pedido",
  generating_purchase: "Aguarde, estamos gerando seu pedido",
  payment_released: "Pagamento habilitado somente para fora Brasil",
  accept_terms_error: "Você precisa aceitar os termos",
  check_accept_terms: "Aceitar termos",
  widget_title_credit: "INFORMACÃO DO CARTÃO",
  inputs_users_credit: {
    input_credit_number: "Número do Cartão",
    input_credit_valid: "Validade (MM/AA)",
    input_credit_name: "Titular",
    input_credit_code: "Código (CVV)",
  },
  title_information: "Informamos que nenhuma das suas informações fica salva, seus dados são repassados para a empresa de pagamento e assim que validados são removidos",
  text_discont_message: "Se você tiver um código promocional ou de desconto, insira-o aqui para resgatá-lo!",
  text_select_value_order: "Digite o valor do mod para efetuar o pagamento, lembre-se de enviar o comprovante para o administrador",
  btn_discont: "Resgatar código",
}

module.exports = { _lang_checkout };
